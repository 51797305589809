@media screen, print {
    @page {
     size: 70mm 20mm; 
     /* Chrome sets own margins, we change these printer settings */
     margin: 0mm; 
   }
    html, body {
       margin: 0px;
       padding: 0px;
   }

   #qrcode-container {
       width: 384px;
   }

   .qrcodes-div {
       width: 350px;
       height: 19mm;
       margin: 1mm auto;
       page-break-after: always;
   }
   /*.col:first-child {
       display: inline-block;
       width: 70px;
       padding-top: 10px;
       padding-left: 1px;
   }
   .col:last-child {
       display: inline-block;
       width: 250px;
       padding-top: 9px;
       float: right;
   }*/
}