/*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #130035;
  --bs-primary-rgb: 13,110,253;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 19,0,53;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #350046;
  --bs-body-bg: #fff;
}
*/
html, body{
  height: 100vh;
  /*overflow: hidden;*/
}
body{
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-size: 12px;
  
}

.main{
  display: flex;
  height: 95vh;
  margin-top: 5vh;
}

header {
  position: fixed;
  width: 100vw;
  height: 10vh;
  background: #313a46;
}

.main-container{
  flex-grow: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

/*.container{
  flex-grow: 1;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
  border: 2px solid rgb(188, 188, 188);
  border-radius: 4px;
  min-height: 93%;
  box-shadow: 0 .5rem 1rem rgb(0 7 14 / 17%) !important;
}

.main .container {
  color: #000
}*/

/*.title{
  font-size: 3em;
}
.info{
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}*/
/*
.btn{
  margin: 0 auto;
  border: none;
  outline: none;
  
  letter-spacing: 1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
}
*/

.sidebar{
  /* width: 260px; */
  flex-shrink: 0;
  /* background-color: rgba(22, 22, 22, 1); */
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
}

.text-right {
  text-align: right;
}

.sidebar-item{
  padding: .75em 1em;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
  border-bottom: 1px solid #9c9c9c;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  
}
.sidebar-item:hover{
  background-color: rgba(255, 255, 255, 0.525);
}

.css-1pq52q7 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1 1 auto;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  line-height: 1.57143;
  font-weight: 600;
}
.css-ellwb2{
  top: 16px;
  gap: calc(0.5 * var(--spacing));
  right: 16px;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
}
.css-j4c6pt{
  font-weight: 600;
  font-size: 0.92rem;
  line-height: 1.57143;
}
.css-1tjwiah{
  width: 48px;
  height: 48px;
  margin-bottom: calc(3 * var(--spacing));
}
.css-5poqnu{
  margin-bottom: calc(1 * var(--spacing));
font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
font-weight: 600;
font-size: 0.875rem;
line-height: 1.57143;
}
.css-yi7yso {
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 112px;
}
.css-y7xf7j {
  font-size: 1.5rem;
}
.css-hn7b8w {
  flex-shrink: 0;
  display: inline-flex;
  background-color: currentcolor;
  mask: url(https://assets.minimals.cc/public/assets/background/shape-square.svg) center center / contain no-repeat;
  top: 0px;
  left: -20px;
  width: 240px;
  z-index: -1;
  height: 240px;
  opacity: 0.24;
  position: absolute;
  color: #078DEE;
}
.css-esqfyn{
  flex-shrink: 0;
  display: inline-flex;
  background-color: currentcolor;
  mask: url("https://assets.minimals.cc/public/assets/background/shape-square.svg") center center / contain no-repeat;
  top: 0px;
  left: -20px;
  width: 240px;
  z-index: -1;
  height: 240px;
  opacity: 0.24;
  position: absolute;
  color: #8E33FF;
}
.css-tso493{
flex-shrink: 0;
display: inline-flex;
background-color: currentcolor;
mask: url("https://assets.minimals.cc/public/assets/background/shape-square.svg") center center / contain no-repeat;
top: 0px;
left: -20px;
width: 240px;
z-index: -1;
height: 240px;
opacity: 0.24;
position: absolute;
color: #FFAB00;
}
.css-1n11yoh{
  flex-shrink: 0;
  display: inline-flex;
  background-color: currentcolor;
  mask: url("https://assets.minimals.cc/public/assets/background/shape-square.svg") center center / contain no-repeat;
  top: 0px;
  left: -20px;
  width: 240px;
  z-index: -1;
  height: 240px;
  opacity: 0.24;
  position: absolute;
  color: #FF5630;
}
.sidebar-title{
  display: flex;
  font-size: 1.3em;
  justify-content: space-between;
}
.sidebar-title span i{
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn{
  cursor: pointer;
  transition: transform .3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn{
  transform: rotate(180deg);
}
.sidebar-content{
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content{
  height: auto;
}

.sidebar-item.plain{
  color: #0c0b0b;
  text-decoration: none;
  background-color: #eee;
    border-bottom: 1px solid;
    font-size: 1.1em;
}
.sidebar-item.plain:hover{
  text-decoration: underline;
}
.sidebar-item.plain i{
  display: inline-block;
  width: 1.7em;
}

.custom-modal {
  padding: 15px;
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #b6b6b6;
  -moz-box-shadow:    0px 0px 10px 7px rgb(204 204 204 / 64%);
  -webkit-box-shadow: 0px 0px 10px 7px rgb(204 204 204 / 64%);
  box-shadow:         0px 0px 10px 7px rgb(204 204 204 / 64%);
  display: none;
  
}

.custom-modal .custom-close {
  text-align: right;
  float: right;
}

.display-block {
  display: block !important;
}

.custom-modal table {
  width: 100%;
}

.custom-modal table th,
.custom-modal table td {
  padding: 5px;
}

.custom-modal .custom-close {
  font-size: 25px;
  cursor: pointer;
}

.width-90 {
  width: 90% !important;
}

.width-75 {
  width: 75% !important; 
}

.height-90 {
  height: 90% !important;
}

.height-75 {
  height: 75% !important; 
}

.cutom-form-control input {
  display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#app-div {
  width: 100vw;
  height: 100vh;
}
#app-div > header {
  height: 60px;
}
#app-div > .container-fluid {
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

#app-div > .container-fluid > .row{
  height: 100%;
}

.form-control,.cutom-form-control input {
  /*padding: 0.3rem 0.75rem;*/
  font-size: 0.9rem;
  line-height: 1;
}
/*.btn {
  padding: 0.5rem 0.75rem;
  line-height: 0.5rem;
  font-size: 0.8rem;
}

.bottom-border {
  box-shadow: inset 0 -2px 0 0 #212529b5;
  margin-bottom: 10px;
}
.form-fields {
  padding: 6px 10px;
}*/
.invalid-feedback {
  display: block !important;
  font-size: .775rem;
  
}
#app-div > .container-fluid > .row > main#pageContainer {
  height: 100%;
  overflow: auto;
}
input.form-control, textarea.form-control,.select.form-select {
  border: 1px solid #ced4da !important;
}
/*select.form-select.form-select-sm.mb-3 {
  border: 1px solid #878787 !important;
}

label {
  color: #1f1f1f;
  font-size: 14px !important;
}
.form-check-input {
  width: 1.3em !important;
  height: 1.3em !important;
  margin: 4px;
}*/
.btn-primary {
  background: #078DEE;
}
.btn-primary:hover {
  box-shadow: 0 8px 16px 0 rgba(7 141 238 / 0.24);
  background: #0352ab;
}

h5 {
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.55556;
  margin: 0px;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow, .css-f3jnds-MuiDataGrid-columnHeaders{
  
  background-color: #f2f5f7;
}
a.canvasjs-chart-credit {
  display: none;
}
.btn-btn-danger {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: #B71D18;
  background-color: rgba(255 86 48 / 0.16);
  white-space: nowrap;
  gap: calc(0.75* 8px);
  padding: calc(0* 8px) calc(0.75* 8px);
  border-radius: 6px;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-btn-success {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: #118D57;
  background-color: rgba(34 197 94 / 0.16);
  white-space: nowrap;
  gap: calc(0.75* 8px);
  padding: calc(0* 8px) calc(0.75* 8px);
  border-radius: 6px;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-btn-warning {
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: #B76E00;
  background-color: rgba(255 171 0 / 0.16);
  white-space: nowrap;
  gap: calc(0.75* 8px);
  padding: calc(0* 8px) calc(0.75* 8px);
  border-radius: 6px;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-btn-secondary{
  height: 24px;
  min-width: 24px;
  line-height: 0;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: #637381;
  background-color: rgba(145 158 171 / 0.16);
  white-space: nowrap;
  gap: calc(0.75 * 8px);
  padding: calc(0 * 8px) calc(0.75 * 8px);
  border-radius: 6px;
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
#pageContainer {
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding-left: calc(2* 8px);
  padding-right: calc(3* 8px) !important;
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: calc(2* 8px);
  padding-bottom: calc(2* 8px);
}
.container {
  background-color: #FFFFFF;
  color: #1C252E;
  background-image: none;
  box-shadow:  0 0 2px 0 rgba(145 158 171 / 0.2), 0 12px 24px -4px rgba(145 158 171 / 0.12);;
  z-index: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: calc(2* 8px);
  border-radius: 16px;
  min-height: 100%;
}
.filter-container {
  padding: 1em;
  flex-direction: column;
  margin: 0px 0px 20px 0px;
  background-color: #FFFFFF;
  color: #1C252E;
  box-shadow:  0 0 2px 0 rgba(145 158 171 / 0.2), 0 12px 24px -4px rgba(145 158 171 / 0.12);;
  z-index: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
}
.col h5 {
  margin-bottom: 1rem;
}
.dropdown-toggle .form-select{
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  color: inherit;
  padding-right: calc(1* 8px);
  padding-left: calc(1.5* 8px);
  gap: calc(1.5* 8px);
  height: 34px;
  border-radius: calc(1* 8px);
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.57143;
  border: solid 1px rgba(145 158 171 / 0.24);
}

.MuiBox-root, .MuiDataGrid-cellContent{
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: 400;
}
.sidebar::-webkit-scrollbar-thumb {
  background: #bfced8;
  border-radius: 8px;
}
.sidebar::-webkit-scrollbar-thumb:hover {
  background:  #bfced8;
}
.sidebar::-webkit-scrollbar-track {
  background: #f0f0f0;
}
.sidebar::-webkit-scrollbar {
  width: 8px;
  height: auto;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #bfced8;
  border-radius: 8px;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background:  #bfced8;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #f2f5f7;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 4px;
  height: auto;
}
button.navbar-toggler.position-absolute.collapsed {
  display: none;
}
