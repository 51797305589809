.mega-menu {
    background-color: #0d6efd;
    overflow: hidden;
    width: 75px;
  }
  
  .menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 40px;
  }
  
  .menu-item {
    position: absolute;
    padding: 14px 20px;
    cursor: pointer;
    color: white;
  }
  
  .menu-item:hover {
    background-color: #575757;
  }
  
  .megadropdown {
    display: block;
    position: relative;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .megadropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .megadropdown li {
    padding: 12px 16px;
    white-space: nowrap;
    cursor: pointer;
    
  }
  
  .megadropdown li:hover {
    background-color: #ddd;
  }
  .menu-item:hover {
    background-color: transparent !important;
}
.megadropdown {
    margin-top: 23px;
    left: -160px;
}
.megadropdown li:hover {
    background-color: transparent !important;
}
.megadropdown li {
   color:#000;
}
.megadropdown li .dropdown {
    margin-top: 10px;
}

#profile-dropdown {
    margin-top: -20px !important;
}