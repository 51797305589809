#home table {
    width:100%;
    border-collapse: separate;
    border-spacing: 2px;
}
#home thead tr th {
    text-align: center;
    padding: 5px;
    color: #fff;
    /* border-left: 2px solid #fff; */
} 

#home thead tr:nth-child(1) th{
    background: #6d97b8;
    /* border-bottom: 2px solid #fff; */
}

#home thead tr:nth-child(2) th:nth-child(1){
    background: #44546a;
    width: 135px;
}

#home thead tr:nth-child(2) th{
    background: #aeaaaa;
    /* border-left: 2px solid #fff;
    border-bottom: 2px solid #fff; */
}

#home thead tr:nth-child(3) th{
    color: #000;
}
#home thead tr:nth-child(3){
    background: #f8cbad;
}

#home tbody tr td{
    /* border-spacing: 8px 2px; */
    border: 1px solid #000;
    padding: 2px 5px;
}

#home tbody tr:last-child {
    font-weight: bold;
}
#home tbody tr:last-child td:first-child {
    text-align: center;
}