@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
.login-page {
  background: #fcfcfc;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
h1,h2,h3,h4,h5,h6,label,span, p, strong, b{
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  
}h1,h2,h3,h4,h5,h6 {
  font-weight: 600;
  }
label {
  font-weight: 400;
  font-size:0.875rem;
}
span, p{
  font-weight: 400;
  font-size: 0.855rem;
}
.container strong{
  font-size:1.102rem;
  font-weight: 600;
  padding-bottom: calc(2*8px);
}
.container b{
  font-size:0.910rem;
  font-weight: 600;
}
.login-page h4 {
  text-align: center;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #f0f0f0;
  box-shadow: 0px 14px 80px rgba(72, 130, 124, 0.2);
  border: 1px solid #f3f3f3;
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
/*.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #167bff;
}*/
.form {
    width: 33%;
    margin: 0px auto;
    height: fit-content;
    position: absolute;
    border: 1px solid #b9b9b9;
    background-color: #ffffff;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.login-logo{
  
  text-align: center;
  background: #94d9e6;
  /*width: 40%;
  margin: 0px auto;
  height: fit-content;
  position: absolute;
  left: 30%;
  top: 12%;*/
}
.login-logo img{
  width: 250px;

}

.sign-in-header {
  text-align: center;
  font-size: 35px !important;
  color: #044fbc;
  font-weight: 700;
}
.sign-in-para {
  font-size: 13px;
    text-align: center;
}
button.btn.btn-primary.btn-lg.mt-2.mb-2 {
  background-color: #044fbc;
}