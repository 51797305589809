.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-center {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
.price, #price input{
  text-align: right;
}
.fnclass{ font-weight: 600;}
.summary_title {
  font-size: 14px;
  padding: 5px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.col.h-100 p {
  font-size: 12px;
}
div#simple-tabpanel-0 input[type="number"] {
  text-align: right;
}div#simple-tabpanel-1 input[type="number"] {
  text-align: right;
}div#simple-tabpanel-3 input[type="number"] {
  text-align: right;
}div#capitalization-view input[type="number"] {
  text-align: right;
}/* div#single-asset-capitalization select */
div#single-asset-capitalization input[type="number"]{
  text-align: right;
}
div#single-asset input[type="number"]{
  text-align: right;
}
.gen_deprec {
  /* height: 45px; */
  background-color: #e6e6e6;
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #d3d1d1;
}
/* Box.css */
.box-container {
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}
.custom-modal-title {
  background-color: #e6e6e6;
  vertical-align: middle;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #b6adad;
}
.notification-icon {
  position: relative;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 25px;
}

.notification-icon .badge {
  background-color:#FF5630;
  color: white;
  border-radius: 50%;
  padding: 0.3em 0.5em;
  position: absolute;
  top: -5px;
  left: 10px;
  font-size: 12px;
}

.notification-popup1 {
  position: absolute;
  top: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
  min-width: 250px;
}
.notification-popup{
  color: var(--palette-text-primary);
  flex-direction: column;
  z-index: var(--zIndex-drawer);
  position: absolute;
  top: 50px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDEyMCAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWxfNDQ2NF81NTMzOCkiIGZpbGwtb3BhY2l0eT0iMC4xIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWxfNDQ2NF81NTMzOCIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxMjAgMS44MTgxMmUtMDUpIHJvdGF0ZSgtNDUpIHNjYWxlKDEyMy4yNSkiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMDBCOEQ5Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzAwQjhEOSIgc3RvcC1vcGFjaXR5PSIwIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg=="), url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIwIiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDEyMCAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWxfNDQ2NF81NTMzNykiIGZpbGwtb3BhY2l0eT0iMC4xIi8+CjxkZWZzPgo8cmFkaWFsR3JhZGllbnQgaWQ9InBhaW50MF9yYWRpYWxfNDQ2NF81NTMzNyIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEyMCkgcm90YXRlKDEzNSkgc2NhbGUoMTIzLjI1KSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGRjU2MzAiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkY1NjMwIiBzdG9wLW9wYWNpdHk9IjAiLz4KPC9yYWRpYWxHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K");
  background-size: 50%, 50%;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  background-color: rgba(var(--palette-background-paperChannel) / 0.9);
  box-shadow: -40px 40px 80px -8px rgba(var(--palette-grey-500Channel) / 0.24);
  width: 100%;
  max-width: 420px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0px;
  background-position: right top, left bottom;
  padding: calc(2* 8px) ;
  border-radius: 6px;
}
.notification-popup h2 {
  margin-top: 0;
}

.notification-popup ul {
  list-style-type: none;
  padding: 0;
}
.new-message-box {
  margin: 15px 0;
  padding-left: 20px;
  margin-bottom: 25px!important;
}

.new-message-box p{
  font-size: 1.15em;
  font-weight: 600;
}

.info-tab {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  top: 8px;
}

.info-tab {
  float: left;
  margin-left: -23px;
}

.info-tab i::before {
  width: 24px;
  height: 24px;
  box-shadow: inset 12px 0 13px rgba(0,0,0,0.5);
}

.info-tab i::after {
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  bottom: -18px;
}

.info-tab i::before, .info-tab i::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -17px;
  transform: rotateX(60deg);
}

.note-box, .warning-box, .tip-box-success, .tip-box-danger, .tip-box-warning, .tip-box-info, .tip-box-alert {
  padding: 5px 5px 3px 5px;
  margin: 5px;
}
.new-message-box-info {
  background: #eeeeee;
  padding: 3px;
  margin: 10px 0;
}

.tip-box-info {
  color: #01579B;
  background: #B3E5FC;
}

.tip-icon-info {
  background: #03A9F4;
}

.tip-icon-info::before {
  font-size: 25px;
  content:"\f129";
  top: 8px;
  left: 11px;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*font-family: FontAwesome;*/
  position: absolute;
  color: white
}

.tip-icon-info i::before {
  background: #03A9F4;
}
/*---filter ---*/
.filter-icon {
  position: relative;
  right: 5px;
  cursor: pointer;
  font-size: 25px;
}

.filter-icon .badge {
  background-color:#f45c21d4;
  color: white;
  border-radius: 50%;
  padding: 0.3em 0.5em;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
}
.filter-popup {
  position: absolute;
  top: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
  min-width: 230px;
  margin-left: 130px;
}

.filter-popup h2 {
  margin-top: 0;
}

.filter-popup ul {
  list-style-type: none;
  padding: 0;
}
.tip-box-filter {
  background: #f4f8fa;
}
/*******info*******/
.new-message-box-alert {
  background: #FF6F00;
  padding: 3px;
  margin: 10px 0;
}

.tip-box-alert {
  color: #212121;
  background: #FFF8E1;
}

.tip-icon-alert {
  background: #FF6F00;
}

.tip-icon-alert::before {
  font-size: 25px;
  content:"\f06a";
  top: 8px;
  left: 11px;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*font-family: FontAwesome;*/
  position: absolute;
  color: white
}

.tip-icon-alert i::before {
  background: #FF6F00;
}
.company-dropdown .dropdown-menu.show, .branch-dropdown .dropdown-menu.show,.department-dropdown .dropdown-menu.show {
  max-height: 200px;
  overflow: overlay;
  font-size: 0.95rem;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5px !important;
  padding-right: 15px !important;
}

select.form-select {
  border: 1px solid #878787 !important;
}
.overlay {
  position: fixed;
  top: 0;
  left: 16%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it appears above all content */
  /*display: none;  Initially hidden */
}

/* Disable pointer events for the page underneath */
.overlay.active + .content {
  pointer-events: none;
  opacity: 0.5; /* Optional: Dim the internal page */
}

.spinner {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.dasboard-card1{
  z-index: 0;
    box-shadow: none;
    position: relative;
  color: #012972;
    background-color: #FFFFFF;
    background-image: linear-gradient(135deg, rgba(204 244 254 / 0.48), rgba(104 205 249 / 0.48));
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border-radius: 16px;
    padding: calc(2* 4px);
}
.dasboard-card2{
  z-index: 0;
  box-shadow: none;
  position: relative;
  color: #27097A;
    background-color: #FFFFFF;
    background-image: linear-gradient(135deg, rgba(239 214 255 / 0.48), rgba(198 132 255 / 0.48));
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border-radius: 16px;
    padding: calc(2* 4px);
}
.dasboard-card3{
  z-index: 0;
  box-shadow: none;
  position: relative;
  color: #7A4100;
    background-color: #FFFFFF;
    background-image: linear-gradient(135deg, rgba(255 245 204 / 0.48), rgba(255 214 102 / 0.48));
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border-radius: 16px;
    padding: calc(2* 4px);
}
.dasboard-card4{
  z-index: 0;
  box-shadow: none;
  position: relative;
  color: #7A0916;
    background-color: #FFFFFF;
    background-image: linear-gradient(135deg, rgba(255 233 213 / 0.48), rgba(255 172 130 / 0.48));
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
    border-radius: 16px;
    padding: calc(2* 4px);
}
.sidebar-item.plain:hover {
  background-color: rgb(7 141 238 / 24%);
  color: #012972;
}
/* Highlight only the selected submenu */
.sidebar-item.plain.highlighted {
  background-color: rgb(7 141 238 / 24%); /* Submenu highlight color */
  color: #012972;
}
.sidebar-item.plain{
  color: #0c0b0b;
  text-decoration: none;
  background-color: #eee;
  font-size: 1.1em;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border: 0px;
  margin: 1px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 100%;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 8px;
  padding-bottom: 4px;
  border-radius: 8px;
  min-height: 40px;
  background-color: #edf7ff;
}
.img_sidebar_icon{
  width: 22px;
}
:root, [data-color-scheme="light"] {
  color-scheme: light;
  --palette-primary-lighter: #CCF4FE;
  --palette-primary-light: #68CDF9;
  --palette-primary-main: #078DEE;
  --palette-primary-dark: #0351AB;
  --palette-primary-darker: #012972;
  --palette-primary-contrastText: #FFFFFF;
  --palette-primary-lighterChannel: 204 244 254;
  --palette-primary-lightChannel: 104 205 249;
  --palette-primary-mainChannel: 7 141 238;
  --palette-primary-darkChannel: 3 81 171;
  --palette-primary-darkerChannel: 1 41 114;
  --palette-primary-contrastTextChannel: 255 255 255;
  --palette-secondary-lighter: #EFD6FF;
  --palette-secondary-light: #C684FF;
  --palette-secondary-main: #8E33FF;
  --palette-secondary-dark: #5119B7;
  --palette-secondary-darker: #27097A;
  --palette-secondary-contrastText: #FFFFFF;
  --palette-secondary-lighterChannel: 239 214 255;
  --palette-secondary-lightChannel: 198 132 255;
  --palette-secondary-mainChannel: 142 51 255;
  --palette-secondary-darkChannel: 81 25 183;
  --palette-secondary-darkerChannel: 39 9 122;
  --palette-secondary-contrastTextChannel: 255 255 255;
  --palette-info-lighter: #CAFDF5;
  --palette-info-light: #61F3F3;
  --palette-info-main: #00B8D9;
  --palette-info-dark: #006C9C;
  --palette-info-darker: #003768;
  --palette-info-contrastText: #FFFFFF;
  --palette-info-lighterChannel: 202 253 245;
  --palette-info-lightChannel: 97 243 243;
  --palette-info-mainChannel: 0 184 217;
  --palette-info-darkChannel: 0 108 156;
  --palette-info-darkerChannel: 0 55 104;
  --palette-info-contrastTextChannel: 255 255 255;
  --palette-success-lighter: #D3FCD2;
  --palette-success-light: #77ED8B;
  --palette-success-main: #22C55E;
  --palette-success-dark: #118D57;
  --palette-success-darker: #065E49;
  --palette-success-contrastText: #ffffff;
  --palette-success-lighterChannel: 211 252 210;
  --palette-success-lightChannel: 119 237 139;
  --palette-success-mainChannel: 34 197 94;
  --palette-success-darkChannel: 17 141 87;
  --palette-success-darkerChannel: 6 94 73;
  --palette-success-contrastTextChannel: 255 255 255;
  --palette-warning-lighter: #FFF5CC;
  --palette-warning-light: #FFD666;
  --palette-warning-main: #FFAB00;
  --palette-warning-dark: #B76E00;
  --palette-warning-darker: #7A4100;
  --palette-warning-contrastText: #1C252E;
  --palette-warning-lighterChannel: 255 245 204;
  --palette-warning-lightChannel: 255 214 102;
  --palette-warning-mainChannel: 255 171 0;
  --palette-warning-darkChannel: 183 110 0;
  --palette-warning-darkerChannel: 122 65 0;
  --palette-warning-contrastTextChannel: 28 37 46;
  --palette-error-lighter: #FFE9D5;
  --palette-error-light: #FFAC82;
  --palette-error-main: #FF5630;
  --palette-error-dark: #B71D18;
  --palette-error-darker: #7A0916;
  --palette-error-contrastText: #FFFFFF;
  --palette-error-lighterChannel: 255 233 213;
  --palette-error-lightChannel: 255 172 130;
  --palette-error-mainChannel: 255 86 48;
  --palette-error-darkChannel: 183 29 24;
  --palette-error-darkerChannel: 122 9 22;
  --palette-error-contrastTextChannel: 255 255 255;
  --palette-common-black: #000000;
  --palette-common-white: #FFFFFF;
  --palette-common-blackChannel: 0 0 0;
  --palette-common-whiteChannel: 255 255 255;
  --palette-common-background: #fff;
  --palette-common-onBackground: #000;
  --palette-common-backgroundChannel: 255 255 255;
  --palette-common-onBackgroundChannel: 0 0 0;
  --palette-grey-50: #FCFDFD;
  --palette-grey-100: #F9FAFB;
  --palette-grey-200: #F4F6F8;
  --palette-grey-300: #DFE3E8;
  --palette-grey-400: #C4CDD5;
  --palette-grey-500: #919EAB;
  --palette-grey-600: #637381;
  --palette-grey-700: #454F5B;
  --palette-grey-800: #1C252E;
  --palette-grey-900: #141A21;
  --palette-grey-50Channel: 252 253 253;
  --palette-grey-100Channel: 249 250 251;
  --palette-grey-200Channel: 244 246 248;
  --palette-grey-300Channel: 223 227 232;
  --palette-grey-400Channel: 196 205 213;
  --palette-grey-500Channel: 145 158 171;
  --palette-grey-600Channel: 99 115 129;
  --palette-grey-700Channel: 69 79 91;
  --palette-grey-800Channel: 28 37 46;
  --palette-grey-900Channel: 20 26 33;
  --palette-grey-A100: #f5f5f5;
  --palette-grey-A200: #eeeeee;
  --palette-grey-A400: #bdbdbd;
  --palette-grey-A700: #616161;
  --palette-divider: rgba(145 158 171 / 0.2);
  --palette-text-primary: #1C252E;
  --palette-text-secondary: #637381;
  --palette-text-disabled: #919EAB;
  --palette-text-primaryChannel: 28 37 46;
  --palette-text-secondaryChannel: 99 115 129;
  --palette-text-disabledChannel: 145 158 171;
  --palette-background-paper: #FFFFFF;
  --palette-background-default: #FFFFFF;
  --palette-background-neutral: #F4F6F8;
  --palette-background-paperChannel: 255 255 255;
  --palette-background-defaultChannel: 255 255 255;
  --palette-background-neutralChannel: 244 246 248;
  --palette-action-hover: rgba(145 158 171 / 0.08);
  --palette-action-selected: rgba(145 158 171 / 0.16);
  --palette-action-focus: rgba(145 158 171 / 0.24);
  --palette-action-disabled: rgba(145 158 171 / 0.8);
  --palette-action-disabledBackground: rgba(145 158 171 / 0.24);
  --palette-action-hoverOpacity: 0.08;
  --palette-action-disabledOpacity: 0.48;
  --palette-action-active: #637381;
  --palette-action-selectedOpacity: 0.08;
  --palette-action-focusOpacity: 0.12;
  --palette-action-activatedOpacity: 0.12;
  --palette-action-activeChannel: 99 115 129;
  --palette-action-selectedChannel: 145;
  --palette-Alert-errorColor: rgb(102, 68, 52);
  --palette-Alert-infoColor: rgb(38, 97, 97);
  --palette-Alert-successColor: rgb(47, 94, 55);
  --palette-Alert-warningColor: rgb(102, 85, 40);
  --palette-Alert-errorFilledBg: var(--palette-error-main, #FF5630);
  --palette-Alert-infoFilledBg: var(--palette-info-main, #00B8D9);
  --palette-Alert-successFilledBg: var(--palette-success-main, #22C55E);
  --palette-Alert-warningFilledBg: var(--palette-warning-main, #FFAB00);
  --palette-Alert-errorFilledColor: #fff;
  --palette-Alert-infoFilledColor: rgba(0, 0, 0, 0.87);
  --palette-Alert-successFilledColor: rgba(0, 0, 0, 0.87);
  --palette-Alert-warningFilledColor: rgba(0, 0, 0, 0.87);
  --palette-Alert-errorStandardBg: rgb(255, 246, 242);
  --palette-Alert-infoStandardBg: rgb(239, 253, 253);
  --palette-Alert-successStandardBg: rgb(241, 253, 243);
  --palette-Alert-warningStandardBg: rgb(255, 250, 239);
  --palette-Alert-errorIconColor: var(--palette-error-main, #FF5630);
  --palette-Alert-infoIconColor: var(--palette-info-main, #00B8D9);
  --palette-Alert-successIconColor: var(--palette-success-main, #22C55E);
  --palette-Alert-warningIconColor: var(--palette-warning-main, #FFAB00);
  --palette-AppBar-defaultBg: var(--palette-grey-100, #F9FAFB);
  --palette-Avatar-defaultBg: var(--palette-grey-400, #C4CDD5);
  --palette-Button-inheritContainedBg: var(--palette-grey-300, #DFE3E8);
  --palette-Button-inheritContainedHoverBg: var(--palette-grey-A100, #f5f5f5);
  --palette-Chip-defaultBorder: var(--palette-grey-400, #C4CDD5);
  --palette-Chip-defaultAvatarColor: var(--palette-grey-700, #454F5B);
  --palette-Chip-defaultIconColor: var(--palette-grey-700, #454F5B);
  --palette-FilledInput-bg: rgba(0, 0, 0, 0.06);
  --palette-FilledInput-hoverBg: rgba(0, 0, 0, 0.09);
  --palette-FilledInput-disabledBg: rgba(0, 0, 0, 0.12);
  --palette-LinearProgress-primaryBg: rgb(160, 211, 248);
  --palette-LinearProgress-secondaryBg: rgb(212, 177, 255);
  --palette-LinearProgress-errorBg: rgb(255, 190, 176);
  --palette-LinearProgress-infoBg: rgb(158, 228, 240);
  --palette-LinearProgress-successBg: rgb(171, 232, 193);
  --palette-LinearProgress-warningBg: rgb(255, 223, 158);
  --palette-Skeleton-bg: rgba(var(--palette-text-primaryChannel, 28 37 46) / 0.11);
  --palette-Slider-primaryTrack: rgb(160, 211, 248);
  --palette-Slider-secondaryTrack: rgb(212, 177, 255);
  --palette-Slider-errorTrack: rgb(255, 190, 176);
  --palette-Slider-infoTrack: rgb(158, 228, 240);
  --palette-Slider-successTrack: rgb(171, 232, 193);
  --palette-Slider-warningTrack: rgb(255, 223, 158);
  --palette-SnackbarContent-bg: rgb(50, 50, 50);
  --palette-SnackbarContent-color: #fff;
  --palette-SpeedDialAction-fabHoverBg: rgb(216, 216, 216);
  --palette-StepConnector-border: var(--palette-grey-400, #C4CDD5);
  --palette-StepContent-border: var(--palette-grey-400, #C4CDD5);
  --palette-Switch-defaultColor: var(--palette-common-white, #FFFFFF);
  --palette-Switch-defaultDisabledColor: var(--palette-grey-100, #F9FAFB);
  --palette-Switch-primaryDisabledColor: rgb(160, 211, 248);
  --palette-Switch-secondaryDisabledColor: rgb(212, 177, 255);
  --palette-Switch-errorDisabledColor: rgb(255, 190, 176);
  --palette-Switch-infoDisabledColor: rgb(158, 228, 240);
  --palette-Switch-successDisabledColor: rgb(171, 232, 193);
  --palette-Switch-warningDisabledColor: rgb(255, 223, 158);
  --palette-TableCell-border: rgba(241, NaN, NaN, 1);
  --palette-Tooltip-bg: rgba(69, 79, 91, 0.92);
  --palette-dividerChannel: 145;
  --shadows-0: none;
  --shadows-1: 0px 2px 1px -1px rgba(145 158 171 / 0.2), 0px 1px 1px 0px rgba(145 158 171 / 0.14), 0px 1px 3px 0px rgba(145 158 171 / 0.12);
  --shadows-2: 0px 3px 1px -2px rgba(145 158 171 / 0.2), 0px 2px 2px 0px rgba(145 158 171 / 0.14), 0px 1px 5px 0px rgba(145 158 171 / 0.12);
  --shadows-3: 0px 3px 3px -2px rgba(145 158 171 / 0.2), 0px 3px 4px 0px rgba(145 158 171 / 0.14), 0px 1px 8px 0px rgba(145 158 171 / 0.12);
  --shadows-4: 0px 2px 4px -1px rgba(145 158 171 / 0.2), 0px 4px 5px 0px rgba(145 158 171 / 0.14), 0px 1px 10px 0px rgba(145 158 171 / 0.12);
  --shadows-5: 0px 3px 5px -1px rgba(145 158 171 / 0.2), 0px 5px 8px 0px rgba(145 158 171 / 0.14), 0px 1px 14px 0px rgba(145 158 171 / 0.12);
  --shadows-6: 0px 3px 5px -1px rgba(145 158 171 / 0.2), 0px 6px 10px 0px rgba(145 158 171 / 0.14), 0px 1px 18px 0px rgba(145 158 171 / 0.12);
  --shadows-7: 0px 4px 5px -2px rgba(145 158 171 / 0.2), 0px 7px 10px 1px rgba(145 158 171 / 0.14), 0px 2px 16px 1px rgba(145 158 171 / 0.12);
  --shadows-8: 0px 5px 5px -3px rgba(145 158 171 / 0.2), 0px 8px 10px 1px rgba(145 158 171 / 0.14), 0px 3px 14px 2px rgba(145 158 171 / 0.12);
  --shadows-9: 0px 5px 6px -3px rgba(145 158 171 / 0.2), 0px 9px 12px 1px rgba(145 158 171 / 0.14), 0px 3px 16px 2px rgba(145 158 171 / 0.12);
  --shadows-10: 0px 6px 6px -3px rgba(145 158 171 / 0.2), 0px 10px 14px 1px rgba(145 158 171 / 0.14), 0px 4px 18px 3px rgba(145 158 171 / 0.12);
  --shadows-11: 0px 6px 7px -4px rgba(145 158 171 / 0.2), 0px 11px 15px 1px rgba(145 158 171 / 0.14), 0px 4px 20px 3px rgba(145 158 171 / 0.12);
  --shadows-12: 0px 7px 8px -4px rgba(145 158 171 / 0.2), 0px 12px 17px 2px rgba(145 158 171 / 0.14), 0px 5px 22px 4px rgba(145 158 171 / 0.12);
  --shadows-13: 0px 7px 8px -4px rgba(145 158 171 / 0.2), 0px 13px 19px 2px rgba(145 158 171 / 0.14), 0px 5px 24px 4px rgba(145 158 171 / 0.12);
  --shadows-14: 0px 7px 9px -4px rgba(145 158 171 / 0.2), 0px 14px 21px 2px rgba(145 158 171 / 0.14), 0px 5px 26px 4px rgba(145 158 171 / 0.12);
  --shadows-15: 0px 8px 9px -5px rgba(145 158 171 / 0.2), 0px 15px 22px 2px rgba(145 158 171 / 0.14), 0px 6px 28px 5px rgba(145 158 171 / 0.12);
  --shadows-16: 0px 8px 10px -5px rgba(145 158 171 / 0.2), 0px 16px 24px 2px rgba(145 158 171 / 0.14), 0px 6px 30px 5px rgba(145 158 171 / 0.12);
  --shadows-17: 0px 8px 11px -5px rgba(145 158 171 / 0.2), 0px 17px 26px 2px rgba(145 158 171 / 0.14), 0px 6px 32px 5px rgba(145 158 171 / 0.12);
  --shadows-18: 0px 9px 11px -5px rgba(145 158 171 / 0.2), 0px 18px 28px 2px rgba(145 158 171 / 0.14), 0px 7px 34px 6px rgba(145 158 171 / 0.12);
  --shadows-19: 0px 9px 12px -6px rgba(145 158 171 / 0.2), 0px 19px 29px 2px rgba(145 158 171 / 0.14), 0px 7px 36px 6px rgba(145 158 171 / 0.12);
  --shadows-20: 0px 10px 13px -6px rgba(145 158 171 / 0.2), 0px 20px 31px 3px rgba(145 158 171 / 0.14), 0px 8px 38px 7px rgba(145 158 171 / 0.12);
  --shadows-21: 0px 10px 13px -6px rgba(145 158 171 / 0.2), 0px 21px 33px 3px rgba(145 158 171 / 0.14), 0px 8px 40px 7px rgba(145 158 171 / 0.12);
  --shadows-22: 0px 10px 14px -6px rgba(145 158 171 / 0.2), 0px 22px 35px 3px rgba(145 158 171 / 0.14), 0px 8px 42px 7px rgba(145 158 171 / 0.12);
  --shadows-23: 0px 11px 14px -7px rgba(145 158 171 / 0.2), 0px 23px 36px 3px rgba(145 158 171 / 0.14), 0px 9px 44px 8px rgba(145 158 171 / 0.12);
  --shadows-24: 0px 11px 15px -7px rgba(145 158 171 / 0.2), 0px 24px 38px 3px rgba(145 158 171 / 0.14), 0px 9px 46px 8px rgba(145 158 171 / 0.12);
  --customShadows-z1: 0 1px 2px 0 rgba(145 158 171 / 0.16);
  --customShadows-z4: 0 4px 8px 0 rgba(145 158 171 / 0.16);
  --customShadows-z8: 0 8px 16px 0 rgba(145 158 171 / 0.16);
  --customShadows-z12: 0 12px 24px -4px rgba(145 158 171 / 0.16);
  --customShadows-z16: 0 16px 32px -4px rgba(145 158 171 / 0.16);
  --customShadows-z20: 0 20px 40px -4px rgba(145 158 171 / 0.16);
  --customShadows-z24: 0 24px 48px 0 rgba(145 158 171 / 0.16);
  --customShadows-dialog: -40px 40px 80px -8px rgba(0 0 0 / 0.24);
  --customShadows-card: 0 0 2px 0 rgba(145 158 171 / 0.2), 0 12px 24px -4px rgba(145 158 171 / 0.12);
  --customShadows-dropdown: 0 0 2px 0 rgba(145 158 171 / 0.24), -20px 20px 40px -4px rgba(145 158 171 / 0.24);
  --customShadows-primary: 0 8px 16px 0 rgba(7 141 238 / 0.24);
  --customShadows-secondary: 0 8px 16px 0 rgba(142 51 255 / 0.24);
  --customShadows-info: 0 8px 16px 0 rgba(0 184 217 / 0.24);
  --customShadows-success: 0 8px 16px 0 rgba(34 197 94 / 0.24);
  --customShadows-warning: 0 8px 16px 0 rgba(255 171 0 / 0.24);
  --customShadows-error: 0 8px 16px 0 rgba(255 86 48 / 0.24);
  --opacity-inputPlaceholder: 0.42;
  --opacity-inputUnderline: 0.42;
  --opacity-switchTrackDisabled: 0.12;
  --opacity-switchTrack: 0.38;
}