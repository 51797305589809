.ReactModal__Content {
    width: 800px !important;
}


 .btn-yellow {
     background-color: yellow;
     color: black;
 }
.btn-yellow:hover {
     background-color: yellow;
     color: black;
 }

.btn-grey {
    background-color: grey;
    color: white;
}
.btn-grey:hover {
    background-color: grey; /* Adjust the shade to a lighter or darker tone */
    color: white; /* Ensure text color remains white on hover */
}
.btn-orange {
    background-color: #ffA500;
    color: white;
}

.btn-orange:hover {
    background-color: #ffa726; /* Adjust the shade to a lighter or darker tone */
    color: white; /* Ensure text color remains white on hover */
}


/* customStyles.css */
.border-box-ticket {
    border: 1px solid #ccc; /* You can customize the color and style of the border */
    padding: 10px;          /* Optional: Adds some padding inside the border */
    margin-bottom: 10px;    /* Optional: Adds some space between the bordered boxes */
    border-radius: 5px;     /* Optional: Adds rounded corners to the border */
}
