.downloadTemplate a {
    text-decoration: none;
    width:100%;
    height: 100%;
    color: #fff;
}

.container > h3 > div {
    width:100%;
    display: block;
}
/*.deprec{
    height: 45px;
}*/
.subject-info-box-1, .subject-info-box-2 {
    float: left;
    width: 35%;
}
.subject-info-box-1 select {
        height: 200px;
        padding: 0;
}.subject-info-box-2 select {
    height: 230px;
    padding: 0;
    
}
.subject-info-box-1 option, .subject-info-box-2  option {
            padding: 4px 10px 4px 10px;
        }

.subject-info-box-1, .subject-info-box-2, option:hover {
            background: #EEEEEE;
        }

.subject-info-arrows {
    float: left;
    width: 25%;
}
.subject-info-arrows input {
        width: 70%;
        margin-bottom: 5px;
    }
.height-80 {
    height: 75% !important;
}
.width-80 {
    width: 75% !important;
}
.month-y {
    background-color:#ebeca478;
}
.month-g{
    background-color:rgb(90 216 90 / 40%);
}

/* Add your modal styles here */
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  
  .ReactModal__Content {
    max-width: 1000px;
    margin: 20px auto;
    background: white;
    padding: 20px;
  }